<template>
  <q-menu
    class="presentation__history-menu"
    @before-show="fetchPresentatinHistory()"
    ref="historyMenu"
    transition-show="jump-down"
    transition-hide="jump-up"
    style="overflow: hidden"
  >
    <q-item header class="presentation__history-menu-header">
      <q-item-section class="presentation__history-menu-header-section">
        <p class="no-margin">Активность</p>
        <nav>
          <q-icon style="cursor: pointer" v-close-popup name="close" size="20px" color="dark" />
        </nav>
      </q-item-section>
    </q-item>
    <q-separator />

    <q-list style="position: relative">
      <div style="height: var(--screen-height)" v-if="displayLoader">
        <q-inner-loading style="z-index: 999" :showing="displayLoader">
          <custom-loader />
        </q-inner-loading>
      </div>
      <div class="presentation__history-serafima" v-if="!displayLoader && historyData.length === 0">
        <q-item-section class="notification-menu-box">
          <q-avatar size="40px">
            <img src="~/assets/img/AI/serafima.png"
            alt="img"
             />
          </q-avatar>
          <div class="notification-menu-box-info">
            <span>
              <p class="no-margin text-regular">Серафима</p>
            </span>
            <p class="no-margin text-message">
              Я тут для теста. Если вы меня видите, значит пока нет ни одного уведомления =(
            </p>
          </div>
        </q-item-section>
      </div>

      <div
        v-if="!displayLoader"
        class="custom-scroll scroll"
        style="height: calc(var(--screen-height) - 130px)"
        ref="scrollTargetRef"
      >
        <q-infinite-scroll
          @load="onLoadnewPage"
          ref="infiniteScrollRef"
          :offset="250"
          :scroll-target="scrollTargetRef"
        >
          <q-item v-for="(item, index) in historyData">
            <q-item-section class="presentation__history-menu-box">
              <div class="presentation__history-menu-box-info">
                <span>
                  <p class="no-margin text-regular">{{ item.title_text }}</p>
                  <p class="no-margin text-pale">{{ formatTime(item.datetime) }}</p>
                </span>
                <span v-if="item.object">
                  <p class="no-margin text-message">{{ item.object.object_type + ', ' }}</p>
                  <p class="no-margin text-message">{{ item.object.object_adress }}</p>
                </span>
                <span v-else>
                  <p class="no-margin text-message">{{ item.description }}</p>
                </span>
              </div>
            </q-item-section>
          </q-item>
          <template v-slot:loading>
            <div class="row justify-center q-my-md">
              <q-spinner-dots color="primary" size="40px" />
            </div>
          </template>
        </q-infinite-scroll>
      </div>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import { apiGet } from '~/services/api'
import { formatTime } from '~/helpers/Dates/formatTime'
import { ToastStore } from '~/store/toastStore'

definePageMeta({
  middleware: 'auth',
})

interface historyEvent {
  id: number
  datetime: string
  title_text: string
  action_type: string
  object: {
    object_type: string
    object_adress: string
    object_id: number
  }
  description: string
}

const props = defineProps({
  presentationID: {
    required: true,
    type: Number,
  },
})

const storeToast = ToastStore()
const historyMenu = ref(null)
const scrollTargetRef = ref()
const infiniteScrollRef = ref()
const displayLoader = ref(true)
const historyData = ref<historyEvent[]>([])
const current_page = ref(2)
const last_page = ref(0)

const onLoadnewPage = async (index: any, done: any) => {
  if (current_page.value != last_page.value + 1 && current_page.value != 1) {
    try {
      const { data } = await apiGet({
        url: `api/presentation/${props.presentationID}/logs`,
        query: { page: current_page.value },
      })
      if (data.value) {
        historyData.value = [...historyData.value, ...data.value.data]
        current_page.value = current_page.value + 1
      }
    } catch (e) {
      console.log(e)
    }
    done()
  } else {
    infiniteScrollRef.value.stop()
  }
}

const fetchPresentatinHistory = async () => {
  displayLoader.value = true
  try {
    const { data } = await apiGet({
      url: `api/presentation/${props.presentationID}/logs`,
    })
    if (data.value) {
      historyData.value = data.value.data
      current_page.value = data.value.meta.current_page
      last_page.value = data.value.meta.last_page
      displayLoader.value = false
      current_page.value = current_page.value + 1
    }
  } catch (e) {
    console.log(e)
    displayLoader.value = true
  }
}
</script>

<style lang="scss">
.presentation {
  &__history {
    &-serafima {
      height: 100px;
      display: grid;
      align-items: center;
      justify-items: center;
    }
    &-menu {
      width: 420px;
      height: 100%;
      max-height: 90% !important;
      right: 0 !important;
      left: unset !important;
      top: 7.5% !important;

      &-header {
        height: 44px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 5px;
        padding-bottom: 5px;
        align-items: center;
        &-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: fit-content;
        }
        &-section > p {
          color: var(--slate-800);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
        }
        &-section > nav {
          display: flex;
          gap: 12px;
        }
      }
      &-box {
        width: 396px;
        padding: 5px;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: flex-start;
        cursor: pointer;

        &-info {
          display: grid;
          margin-left: 12px;
        }
        &-info > span {
          display: flex;
          gap: 8px;
        }
        &-isread {
          align-self: center;
        }
      }
      &-box:hover {
        background: var(--slate-100);
      }
    }
  }
}

.text {
  &-pale {
    color: var(--slate-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &-regular {
    color: var(--slate-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  &-message {
    color: var(--slate-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.custom-scroll {
  scrollbar-width: thin;
  margin-right: 5px;
  .q-item {
    padding: 8px 7px;
  }
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: var(--slate-200);
}
</style>
